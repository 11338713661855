// This should be entirely for the Projects endpoing for the old active learning app.

import { ApiService } from "./api.service";
import useSWR from "swr";
import { asyncForEach, log, stringify } from "lib/utils";
import tasksSlice from "store/tasks";
import { useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import { getAuthToken } from "lib/use-auth";
import { getNewTestFromStats, NewTest } from "lib/stats";
import { useRouter } from "next/router";
import { Label } from "types/labels";
import { PublicProject } from "types/public";
import { AIStatus, Evaluation, Policy, Project } from "types/project";
import AnnotationApi from "./annotations.service";
import { AxiosPromise } from "axios";

export interface ProjectUpdateRequest {
  id: number;
  name?: string;
  instructions?: string;
  guidelines?: string;
  description?: string;
  learner_config?: Record<string, any>;
  policy?: Policy;
  task_allocation_strategy?: "automatic" | "manual";
  task_allocation_batch_size?: number;
}

const ProjectApi = {
  getProjects: () => ApiService.get("/projects"),

  getProject: ({ projectId }) => ApiService.get(`/projects/${projectId}`),

  deleteById: ({ projectId }) => ApiService.remove(`/projects/${projectId}`),

  cloneById: ({ projectId }) => ApiService.post(`/projects/${projectId}/clone`, {}),

  updateProject: (project) => ApiService.put(`/projects/${project.id}`, project),

  enableSharing: ({ projectId }) => ApiService.post(`/projects/${projectId}/share`, {}),

  disableSharing: ({ projectId }) => ApiService.post(`/projects/${projectId}/revoke`, {}),

  deleteLabelById: async ({ outputId, labelId }) => {
    log(`in project.service deleting label in output ${outputId} label ${labelId}`);
    return ApiService.remove(`/outputs/${outputId}/labels/${labelId}`);
  },

  addLabel: ({ label }: { label: Label }) => {
    log(`in project.service adding new label to output ${label.output_id} with name ${label.name} and description ${label.description}`);
    return ApiService.post(`/outputs/${label.output_id}/labels`, {
      name: label.name,
      description: label.description,
    });
  },

  updateLabel: ({ label }: { label: Label }) => {
    // Not all label properties are actually used in the API
    const updatedLabel = {
      name: label.name,
      description: label.description,
    };

    // TODO: backend returns differently shaped
    // data when fetching labels vs showing changed labels
    // chasing with backend
    const outputId = label.output_id || label?.output?.id;

    log(`in project.service updating output ${outputId} label ${label.id}, ${stringify(updatedLabel)}`);
    return ApiService.put(`/outputs/${outputId}/labels/${label.id}`, updatedLabel);
  },

  addDatasetToProject({ projectId, inputs, outputs, dataset }) {
    return ApiService.post(`/projects/${projectId}/datasets`, {
      dataset_id: dataset.id,
      inputs: (Object.values(inputs) as Array<any>).map((input) => ({
        name: input.name,
        data_type: input.dataType,
        data_sources: [{ field_id: input.fieldId }],
      })),
      outputs: (Object.values(outputs) as Array<any>).map((output) => ({
        name: output.name,
        data_type: output.dataType,
        data_sources: [{ field_id: output.fieldId }],
      })),
    });
  },

  removeDatasetFromProject({ projectId, datasetId }) {
    return ApiService.remove(`/projects/${projectId}/datasets/${datasetId}`);
  },

  createTasksForUser(projectId: number, userEmail: string, datapoints: number): AxiosPromise<{ created_tasks: number }> {
    return ApiService.post(`/projects/${projectId}/create-tasks-for-user`, {
      user: userEmail,
      datapoints,
    });
  },

  reassignTasks(projectId: number, sourceUserEmail: string, targetUserEmail: string, tasks: number): AxiosPromise<{ reassigned_tasks: number }> {
    return ApiService.post(`/projects/${projectId}/reassign-tasks`, {
      source_user: sourceUserEmail,
      target_user: targetUserEmail,
      tasks,
    });
  },
};

interface PaginatedProjectsProps {
  size?: number;
  page?: number;
  filter?: string;
  sortBy?: string;
  order?: "asc" | "desc";
}

export const useProjects = (props: PaginatedProjectsProps, swrOptions = {}) => {
  const { size = 10, page = 0, filter, sortBy = "created_at", order = "desc" } = props;

  const { data, error, mutate } = useSWR([`/projects?size=${size}&page=${page}&filter=${filter}&sort_by=${sortBy}&order=${order}`, getAuthToken()], swrOptions);

  const deleteById = async ({ projectId }) => {
    const nextState = {
      ...data,
      records: data.records.filter((p) => p.id !== projectId),
      total: data.total - 1,
    };

    mutate(nextState, false);
    await ProjectApi.deleteById({ projectId });
    mutate(nextState);
  };

  const cloneById = async ({ projectId }) => {
    await ProjectApi.cloneById({ projectId });
    mutate(data);
  };

  return { ...data, size, page, error, loading: !error && !data, mutate, deleteById, cloneById };
};

export const useProject = ({ projectId }, swrOptions = {}) => {
  const { data: project, error, mutate } = useSWR<Project>(projectId ? [`/projects/${projectId}`, getAuthToken()] : null, swrOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    // TODO:/NB: This gets called in every instance of the hook.
    // It's a bit unpleasant and unecessary but fairly harmless, I think. When we've settled
    // on frontend state management + REST/GraphQL Api, hopefully the unhappy hybrid SWR + redux causing issues like this
    // goes away.
    if (!isEmpty(project)) {
      dispatch(tasksSlice.actions.setProject({ project }));
    }
  }, [dispatch, project]);

  const update = async (payload: ProjectUpdateRequest) => {
    mutate((project) => ({ ...project, ...payload }), false);
    await ProjectApi.updateProject(payload);
    mutate((project) => ({ ...project, ...payload }));
  };

  const enableSharing = async () => {
    const response = await ProjectApi.enableSharing({ projectId });
    mutate({ ...project, shared_id: response.data["shared_id"] });
  };

  const disableSharing = async () => {
    mutate({ ...project, shared_id: undefined }, false);
    await ProjectApi.disableSharing({ projectId });
    mutate();
  };

  return { project, mutate, error, loading: !error && !project, update, enableSharing, disableSharing };
};

export const useProjectDatasets = ({ projectId }) => {
  const { project, mutate } = useProject({ projectId });

  const addDataset = async ({ inputs, outputs, dataset }) => {
    await ProjectApi.addDatasetToProject({ projectId, inputs, outputs, dataset });
    mutate();
  };

  const removeDataset = async ({ datasetId }) => {
    await ProjectApi.removeDatasetFromProject({ projectId, datasetId });
    mutate();
  };

  return { datasets: project.datasets, addDataset, removeDataset };
};

export const useProjectLabels = ({ projectId }) => {
  const { project, mutate } = useProject({ projectId });
  const labels = (project && project.outputs && project.outputs[0].labels) || [];

  const addLabel = async ({ label }: { label: Label }) => {
    const { data } = await ProjectApi.addLabel({ label });
    mutate({
      ...project,
      outputs: [{ ...project.outputs[0], labels: data }, ...project.outputs.slice(1)],
    });
  };

  const addLabels = async ({ labels }: { labels: Label[] }) => {
    await asyncForEach(labels, async (label) => {
      try {
        const { data } = await ProjectApi.addLabel({ label });

        // Incrementally update the state as labels get added
        const nextState = {
          ...project,
          outputs: project.outputs.map((output) => {
            if (output.id !== label.output_id) {
              return output;
            }
            return { ...output, labels: data };
          }),
        };

        mutate(nextState, false);
      } catch (error) {
        log(`Error adding new label ${error.message}`);
        throw error;
      }
    });

    mutate();
  };

  const updateLabel = async ({ label }) => {
    const nextState = {
      ...project,
      outputs: project.outputs.map((output) => {
        if (output.id !== label.outputId) {
          return output;
        }

        return { ...output, labels: [...output.labels, label] };
      }),
    };

    mutate(nextState, false);

    let response;
    try {
      response = await ProjectApi.updateLabel({ label });
    } catch (error) {
      // reset the label as if not edited
      mutate(project);
      throw error;
    }

    mutate(nextState);

    return response;
  };

  const deleteLabel = async ({ outputId, labelId }) => {
    const nextState = {
      ...project,
      outputs: project.outputs.map((output) => {
        if (output.id !== outputId) {
          return output;
        }

        return { ...output, labels: output.labels.filter((l) => l.id !== labelId) };
      }),
    };

    mutate(nextState, false);

    let response;

    try {
      response = await ProjectApi.deleteLabelById({ outputId, labelId });
    } catch (error) {
      // Reset the label as if not deleted
      mutate(project);
      throw error;
    }

    mutate(nextState);

    return response;
  };

  return { labels, addLabel, addLabels, updateLabel, deleteLabel };
};

export const useProjectStats = ({ projectId }, swrOptions = {}) => {
  const { data: stats, error, mutate } = useSWR<Evaluation[]>(projectId ? [`/projects/${projectId}/stats`, getAuthToken()] : null, swrOptions);
  const testAccuracy: NewTest | undefined = useMemo(() => getNewTestFromStats(stats), [stats]);
  return { stats, testAccuracy, mutate, loading: !error && !stats, error };
};

export const useProjectStatus = ({ projectId }, swrOptions = {}) => {
  const {
    data: status,
    mutate,
    error,
  } = useSWR<{ current_status: AIStatus }>(projectId ? [`/projects/${projectId}/status`, getAuthToken()] : null, swrOptions);
  return { status, mutate, loading: !error && !status, error };
};

export const useProjectTaskStats = ({ projectId }, swrOptions = {}) => {
  const { data: stats, mutate, error } = useSWR(projectId ? [`/projects/${projectId}/task-summary-statistics`, getAuthToken()] : null, swrOptions);
  return { stats, mutate, loading: !error && !stats, error };
};

interface PaginatedAnnotationsProps {
  projectId: number;
  size?: number;
  page?: number;
  complete?: boolean;
  flagged?: boolean;
  reviewed?: boolean;
  keywords?: string;
  labels?: Array<string>;
  user?: string;
  taskId?: number;
}

const getPaginatedAnnotationsUrlFromProps = (props: PaginatedAnnotationsProps) => {
  const { projectId, size = 50, page = 0, complete, flagged, keywords, labels, reviewed, user, taskId } = props;

  const params = new URLSearchParams();

  params.append("project_id", projectId.toString());
  params.append("size", size.toString());
  params.append("page", page.toString());

  if (complete !== undefined) {
    params.append("complete", complete.toString());
  }
  if (flagged !== undefined) {
    params.append("flagged", flagged.toString());
  }
  if (reviewed !== undefined) {
    params.append("reviewed", reviewed.toString());
  }
  if (user !== undefined) {
    params.append("user", user.toString());
  }
  if (keywords !== undefined) {
    params.append("keywords", keywords);
  }
  if (labels !== undefined) {
    labels.forEach((l) => params.append("labels", l));
  }
  if (taskId !== undefined) {
    params.append("task_id", taskId.toString());
  }

  return `/annotations?${params.toString()}`;
};

export interface UpdateAnnotationArgs {
  annotationId: string;
  reviewed?: boolean;
  start?: number;
  end?: number;
  text?: string;
}

export interface UpdateAnnotationLabelArgs {
  annotationId: string;
  label: Label;
}

export const useProjectAnnotations = (props: PaginatedAnnotationsProps, swrOptions = {}) => {
  const { projectId } = props;
  const url = getPaginatedAnnotationsUrlFromProps(props);

  const { data, mutate, error } = useSWR(projectId ? [url.toString(), getAuthToken()] : null, swrOptions);

  const updateAnnotation = async ({ annotationId, reviewed, start, end, text }: UpdateAnnotationArgs) => {
    const nextState = {
      ...data,
      records: data.records?.map((annotation) => {
        if (annotation.id !== annotationId) {
          return annotation;
        }
        return {
          ...annotation,
          reviewed,
          start,
          end,
          text,
        };
      }),
    };

    mutate(nextState, false);
    await AnnotationApi.updateAnnotation({ annotationId, reviewed, start, end, text });
    mutate(nextState);
  };
  const updateAnnotationLabel = async ({ annotationId, label }: UpdateAnnotationLabelArgs) => {
    const nextState = {
      ...data,
      records: data.records?.map((annotation) => {
        if (annotation.id !== annotationId) {
          return annotation;
        }
        return {
          ...annotation,
          label_id: label.id,
          label,
        };
      }),
    };

    mutate(nextState, false);
    await AnnotationApi.updateAnnotation({ annotationId, labelId: label.id });
    mutate(nextState);
  };

  const removeAnnotation = async ({ annotationId }: { annotationId: string }) => {
    const nextState = {
      ...data,
      records: data.records?.filter((annotation) => annotation.id !== annotationId),
    };

    mutate(nextState, false);
    await AnnotationApi.removeAnnotation({ annotationId });
    mutate(nextState);
  };

  return { ...data, mutate, updateAnnotation, updateAnnotationLabel, removeAnnotation, loading: !error && !data, error };
};

interface PaginatedDataProps {
  projectId: number;
  size?: number;
  page?: number;
  status?: Array<string>;
  flagged?: Array<boolean>;
  sort?: string;
}

const getPaginatedDataUrlFromProps = (props: PaginatedDataProps) => {
  const { projectId, size = 10, page = 0, status, flagged, sort } = props;

  const params = new URLSearchParams();

  params.append("size", size.toString());
  params.append("page", page.toString());

  if (status?.length > 0) {
    status.forEach((s) => params.append("status", s));
  }

  if (flagged?.length > 0) {
    params.append("flagged", flagged[0]?.toString());
  }

  if (sort) {
    params.append("sort", sort);
  }

  return `/projects/${projectId}/data?${params.toString()}`;
};

export const useProjectData = (props: PaginatedDataProps, swrOptions = {}) => {
  const { projectId } = props;
  const url = getPaginatedDataUrlFromProps(props);

  const { data, mutate, error } = useSWR(projectId ? [url.toString(), getAuthToken()] : null, swrOptions);

  return { ...data, mutate, loading: !error && !data, error };
};

/**
 * Convenience wrapper around useProject() which
 * takes the project from the route parameters
 */
export const useProjectFromRouter = () => {
  const router = useRouter();
  const { projectId } = router.query;
  return useProject({ projectId });
};

/**
 * Convenience wrapper around useProjectLabels()
 * which takes the project from the route parameters
 */
export const useProjectLabelsFromRouter = () => {
  const router = useRouter();
  const { projectId } = router.query;
  return useProjectLabels({ projectId });
};

export const usePublicProject = (shareId: string, swrOptions = {}) => {
  const { data: publicProject, error } = useSWR<PublicProject>(shareId ? [`/public/${shareId}`, getAuthToken()] : null, swrOptions);
  return { publicProject, error, loading: !error && !publicProject };
};

export default ProjectApi;
