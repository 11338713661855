import { Evaluation } from "types/project";

export interface NewTest {
  current: number;
  previous?: number;
  improvement?: number;
}

export function getNewTestFromStats(stats: Evaluation[] | undefined): NewTest | undefined {
  let newTest: NewTest;
  try {
    if (stats?.length) {
      stats?.sort((a, b) => a.id - b.id);
      const currentStat = stats[stats.length - 1];
      const currentTest = currentStat.eval_metrics.test_score * 100;
      newTest = { current: currentTest };

      if (stats?.length > 1) {
        const previousStat = stats[stats.length - 2];
        const previousTest = previousStat.eval_metrics.test_score * 100;
        let percentageImprovement = 0;
        if (previousTest) {
          percentageImprovement = ((currentTest - previousTest) / previousTest) * 100;
        }
        newTest = { ...newTest, previous: previousTest, improvement: percentageImprovement };
      }
    }
  } catch (e) {
    console.log("Failed to get new test from stats");
    console.log(stats);
  }
  return newTest;
}
